import React from "react"
import { Router } from "@reach/router"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReportActions from "../components/report/reportActions"
import ReportAttributes from "../components/report/reportAttributes"
import ReportMap from "../components/report/reportMap"
import ReportStreetview from "../components/report/reportStreetview"
import ReportMedian from "../components/report/reportMedian"
import ReportHistory from "../components/report/reportHistory"
import ReportOtherProperties from "../components/report/reportOtherProperties"
import ReportEstimates from "../components/report/reportEstimates"
import PropertyLookup from "../components/propertyLookup"
import Spinner from "../components/spinner"
import { useWindowSize } from "../scripts/utils"

// Page with property report

const ReportPage = ({ propertyId }) => {
  const [propertyData, setPropertyData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [isError, setIsError] = React.useState(false)
  const formattedAddress = propertyData ? propertyData.address : null
  const windowSize = useWindowSize()
  

  // Request property data on page mount
  React.useEffect(() => {
    // Async function to fetch property data
    async function getPropertyData() {
      if (process.env.GATSBY_API_ADDRESS_ENDPOINT && propertyId) {
        // Set loading state
        setIsLoading(true)
        setIsError(false)
        return await axios
          .get(`${process.env.GATSBY_API_ADDRESS_ENDPOINT}${propertyId}`, {
            params: {
              format: "json",
              query: propertyId,
            },
          })
          .then(response => {
            // Success
            setIsLoading(false)
            return response.data
          })
          .catch(error => {
            // Error
            setIsLoading(false)
            setIsError(true)
            return false
          })
      } else {
        setIsError(true)
        return false
      }
    }

    // Async fetch the data and set state
    async function getData() {
      const data = await getPropertyData()
      setPropertyData(data)
    }

    getData()
  }, [propertyId])

  return (
    <>
      <SEO title="Property Report" />

      {isLoading && (
        <div className="property-report-loader">
          <Spinner />
        </div>
      )}

      {!isLoading && isError ? (
        <div className="property-report-error">
          There was an error loading this report.
        </div>
      ) : null}

      {Boolean(propertyData) && !isLoading && !isError ? (
        windowSize.width > 766 ? (
          <>
            <section id="property-report" className="sub-page">
              <div className="container">
                <div className="row report-title">
                  <h1>{formattedAddress}</h1>
                </div>
                <div className="row report-row">
                  <div className="grid-553-col">
                    <div className="map-widget right-gap">
                      <ReportStreetview address={formattedAddress} />
                    </div>
                    <div className="map-widget right-gap">
                      <ReportMap address={formattedAddress} />
                    </div>
                    <div className="flex flex-col">
                      <ReportActions
                        propertyId={propertyId}
                        address={formattedAddress}
                      />
                    </div>
                  </div>
                </div>
                <div className="row report-row">
                  <div className="grid-2-col">
                    <ReportAttributes 
                      propertyData={propertyData}
                      gap='right-gap'
                    />
                    <ReportEstimates
                      propertyData={propertyData}
                      gap='left-gap'
                    />
                  </div>
                </div>
                <div className="row report-row">
                  <ReportMedian
                    propertyData={propertyData}
                  />
                </div>
                <div className="row report-row">
                  <div className="grid-2-col">
                    <div className="report-widget right-gap">
                      <div className="report-widget-head">
                        <h5>Sales history</h5>
                      </div>
                      <ReportHistory data={propertyData.sale_events} />
                    </div>
                    <div className="report-widget left-gap">
                      <div className="report-widget-head">
                        <h5>Rental history</h5>
                      </div>
                      <ReportHistory data={propertyData.rental_events} />
                    </div>
                  </div>
                </div>
                <div className="row report-row">
                  <div className="report-widget">
                    <div className="report-widget-head">
                      <h5>Comparable properties</h5>
                    </div>
                    <ReportOtherProperties
                      data={propertyData.comparable_properties}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="search-subpage">
              <div className="container">
                <div className="row">
                  <h4>Search another property</h4>
                  <PropertyLookup
                    minQueryLength={5}
                    debounceDelay={500}
                    dropdownTop={true}
                  />
                </div>
              </div>
            </section>

            <DisclaimerSection />
          </>
        ) : (
          //mobile layout
          <>
            <section id="property-report" className="sub-page">
              <div className="container">
                <div className="row report-title">
                  <h1>{formattedAddress}</h1>
                </div>
                <div className="row report-row">
                  <div className="grid-2-col">
                    <ReportEstimates
                      propertyData={propertyData}
                      gap='left-gap'
                    />
                    <ReportAttributes 
                      propertyData={propertyData}
                      gap='right-gap'
                    />
                  </div>
                </div>
                <div className="row report-row">
                  <div className="grid-553-col">
                    <div className="map-widget right-gap">
                      <ReportStreetview address={formattedAddress} />
                    </div>
                    <div className="map-widget right-gap">
                      <ReportMap address={formattedAddress} />
                    </div>
                    <div className="flex flex-col">
                      <ReportActions
                        propertyId={propertyId}
                        address={formattedAddress}
                      />
                    </div>
                  </div>
                </div>
                <div className="row report-row">
                  <ReportMedian
                    propertyData={propertyData}
                  />
                </div>
                <div className="row report-row">
                  <div className="grid-2-col">
                    <div className="report-widget right-gap">
                      <div className="report-widget-head">
                        <h5>Sales history</h5>
                      </div>
                      <ReportHistory data={propertyData.sale_events} />
                    </div>
                    <div className="report-widget left-gap">
                      <div className="report-widget-head">
                        <h5>Rental history</h5>
                      </div>
                      <ReportHistory data={propertyData.rental_events} />
                    </div>
                  </div>
                </div>
                <div className="row report-row">
                  <div className="report-widget">
                    <div className="report-widget-head">
                      <h5>Comparable properties</h5>
                    </div>
                    <ReportOtherProperties
                      data={propertyData.comparable_properties}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="search-subpage">
              <div className="container">
                <div className="row">
                  <h4>Search another property</h4>
                  <PropertyLookup
                    minQueryLength={5}
                    debounceDelay={500}
                    dropdownTop={true}
                  />
                </div>
              </div>
            </section>

            <DisclaimerSection />
          </>
        )
      ) : null}
    </>
  )
}

const DisclaimerSection = () => (
  <section>
  <div className="container">
    <div className="row">
      <div className="disclaimer">
        <p>
          An AVM Estimate is not an accurate representation of the
          market value of the subject property and must not be
          construed as or relied upon as a professional valuation. An
          AVM Estimate is a computer generated analytic which
          estimates the market value of a residential property and is
          generated by a mathematical model in reliance on available
          data (which may be out of date, inaccurate or incomplete);
          without the physical inspection of the subject property and
          without taking into account any market conditions
          (environmental, planning, or economic) or observable
          property features (adverse features, risks, improvements,
          renovations, aesthetics, views or aspect) which may affect
          the value. An AVM Estimate is current only at the date of
          publication or supply. Vali expressly excludes any
          warranties and representations that an AVM Estimate is an
          accurate representation as to the market value of the
          subject property. To the full extent not prohibited by law,
          Vali excludes all liability for any loss or damage
          howsoever arising suffered by the Customer, whether as a
          result of the Customer's reliance on the accuracy of an AVM
          Estimate or otherwise arising in connection with an AVM
          Estimate.
        </p>
      </div>
    </div>
  </div>
</section>
)

// Error page to handle incorrect or missing property id

const Report404Page = () => (
  <>
    <SEO title="404: Property Not found" />
    <h1>PROPERTY NOT FOUND</h1>
    <p>Sorry, that property doesn't appear to exist.</p>
    <section className="search-subpage">
      <div className="container">
        <div className="row">
          <h4>Search another property</h4>
          <PropertyLookup
            minQueryLength={5}
            debounceDelay={500}
            dropdownTop={true}
          />
        </div>
      </div>
    </section>
  </>
)

// Gatsby page with router to handle dynamic routing to property

const PropertyReportPage = () => (
  <Layout>
    <Router basepath="/property-report">
      <ReportPage path="/:propertyId" />
      <Report404Page default />
    </Router>
  </Layout>
)

export default PropertyReportPage
