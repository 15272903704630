import React from "react"
import { titleCase } from "../../scripts/utils"

// Property attributes block

const ReportAttributes = ({ propertyData, gap }) => {
  return (
    <div className={"report-widget " + gap}>
      <div className="report-widget-head">
        <h5>Attributes</h5>
      </div>
      <div className="report-widget-body">
        <div className="report-item">
          <span className="ri-title">Bedrooms:</span>
          <span>
            {propertyData.property_features.bedrooms || ""}
          </span>
        </div>
        <div className="report-item">
          <span className="ri-title">Bathrooms:</span>
          <span>
            {propertyData.property_features.bathrooms || ""}
          </span>
        </div>
        <div className="report-item">
          <span className="ri-title">Parking:</span>
          <span>
            {propertyData.property_features.car_parks || ""}
          </span>
        </div>
        <div className="report-item">
          <span className="ri-title">Land area:</span>
          <span>
            {propertyData.property_features.land_size || ""} m
            <sup>2</sup>
          </span>
        </div>
        <div className="report-item">
          <span className="ri-title">Year built:</span>
          <span>
            {propertyData.property_features.year_built || ""}
          </span>
        </div>
        <div className="report-item">
          <span className="ri-title">Property type:</span>
          <span>{titleCase(propertyData.property_type)}</span>
        </div>
      </div>
    </div>
  )
}

export default ReportAttributes
