import React from "react"
import ReportScatterplotGraph from "./reportScatterplotGraph"
import { titleCase } from "../../scripts/utils"

// Medians block

const ReportMedian = ({ propertyData , gap}) => {
  return (
  <div className="report-widget">
    <div className="report-widget-head">
      <h5>Median {propertyData.property_type.toLowerCase()} prices in {titleCase(propertyData.suburb)}</h5>
    </div>
    <div className="report-widget-body">
      {propertyData.suburb_sales_history.length ? (
        <ReportScatterplotGraph
          data={propertyData.suburb_sales_history}
        />
      ) : (
        <div class="report-widget-no-data">
          No suburb sales history available
        </div>
      )}
    </div>
  </div>
  )
}

export default ReportMedian
