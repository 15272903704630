import React from "react"
import ReportEstimateRange from "./reportEstimateRange"

// Estimates block

const ReportEstimates = ({ propertyData , gap}) => {
  return (
    <div className={"report-widget " + gap}>
      <div className="report-widget-head">
        <h5>Estimates</h5>
      </div>
      <div className="report-widget-body">
        <h6>Estimated Value</h6>
        {propertyData.estimated_price_sale ? <ReportEstimateRange
          data={propertyData.estimated_price_sale}
        /> : ""}
        <h6>Estimated Rental</h6>
        {propertyData.estimated_price_rental ? <ReportEstimateRange
          data={propertyData.estimated_price_rental}
        /> : ""}
      </div>
    </div>
  )
}

export default ReportEstimates
